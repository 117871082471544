import { observer } from "mobx-react-lite";
import { useState,useEffect } from "react";
import {useNavigate} from "react-router-dom";
import { useStore } from "../../app/stores/store";
import ResponsiveCategoryPage from "./categories/ResponsiveCategoriesPage";
import AboutUsPage from "../../features/home/AboutUsPage";
import ResponsiveProductPage from "./categories/ResponsiveProductPage";

export default observer(function ResponsiveHomePage() {
    const [toggleBtn, setToggleBtn] = useState(true);
    const navigate = useNavigate();
  const toggle = () => setToggleBtn(val => !val);
  const { commonStore,modalStore,categoryStore,productStore} = useStore();    
    const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min, max) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
  };  
  const {categoryByName,loadCategoryList,categoryreload,setCategoryReload} = categoryStore;
  const {productreload,productByName,loadProductList,setProductReload} = productStore;
  useEffect(() => {    
    if(categoryreload || categoryByName === undefined)
    {
        loadCategoryList().then(()=>{
            setCategoryReload(false);
        })
    }
    if(categoryByName !== undefined)
    {
        categoryByName.map((category,index)=>{
            
        })
    }
  },[categoryreload])
  useEffect(() => {    
    if(productreload || productByName === undefined)
    {
        loadProductList().then(()=>{
            setProductReload(false);
        })
    }
    if(productByName !== undefined)
    {
      productByName.map((product,index)=>{
            
        })
    }
  },[productreload])
    return (
        <>
        <section data-bs-version="5.1" className="features4 audiom4_features4 cid-u7PF8iKfB6 mbr-parallax-background" id="features4-20">
<div className="mbr-overlay" style={{opacity:0.4,backgroundColor:'rgb(0, 0, 0)'}}></div>
<div className="container">
    <div className="row">
        <div className="mbr-white col-md-12 col-lg-12">
            <div className="title-align align-center">
                <h3 className="mbr-section-subtitle mbr-semibold align-center mbr-light mbr-fonts-style display-4">Fashion Fusion</h3>
            </div>
            <h1 className="mbr-section-title align-center mbr-bold pb-5 mbr-fonts-style display-2">Fashion is Being Introduced here</h1>
        </div>
        {!categoryreload && categoryByName.map((cat,key)=>(
            <div className="card p-3 col-12 col-md-6 col-lg-4">        
            <div className="card-wrapper card3">
                <img src={cat.categoryImage} alt={cat.name} />                
                <div className="card-box">                    
                    <h4 className="card-title align-left mbr-semibold mbr-white mbr-fonts-style display-6" style={{textAlign:'left'}}>
                        <strong>{cat.name}</strong>
                    </h4>                                       
                </div>
            </div>            
        </div>
        ))}
        <div className="mbr-white col-md-12 col-lg-12">
            <div className="title-align align-center">
                <h3 className="mbr-section-subtitle mbr-semibold align-center mbr-light mbr-fonts-style display-4">Fashion Fusion Collections</h3>
            </div>            
        </div>

        {/* <div className="card p-3 col-12 col-md-6 col-lg-4">
            <div className="card-wrapper card1">
                <img src="assets/images/westerdressimages-1-225x225.webp" alt="Dresses" />
                <div className="card-box">
                    <h5 className="card-date align-left mbr-semibold mbr-white mbr-fonts-style display-4">
                        <strong>2025</strong>
                    </h5>
                    <h4 className="card-title align-left mbr-semibold mbr-white mbr-fonts-style display-5">
                        <strong>Dresses</strong>
                    </h4>
                </div>
            </div>            
        </div> */}

        {/* <div className="card p-3 col-12 col-md-6 col-lg-4">
            <div className="card-wrapper card2">
                <img src="assets/images/skirtsimages-194x259.webp" alt="" />
                <div className="card-box">
                    <h5 className="card-date align-left mbr-semibold mbr-white mbr-fonts-style display-4">
                        <strong>2026</strong>
                    </h5>
                    <h4 className="card-title align-left mbr-semibold mbr-white mbr-fonts-style display-5">
                        <strong>Skirts</strong>
                    </h4>
                </div>
            </div>
            
        </div> */}

        {/* <div className="card p-3 col-12 col-md-6 col-lg-4">
            <div className="card-wrapper card3">
                <img src="assets/images/jumpsuitdownload-194x259.webp" alt="" />
                <div className="card-box">
                    <h5 className="card-date align-left mbr-semibold mbr-white mbr-fonts-style display-4">
                        <strong>2027</strong>
                    </h5>
                    <h4 className="card-title align-left mbr-semibold mbr-white mbr-fonts-style display-5">
                        <strong>Jumpsuit</strong>
                    </h4>
                </div>
            </div>
            
        </div> */}
        {/* <div className="card p-3 col-12 col-md-6 col-lg-4">
            <div className="card-wrapper card1">
                <img src="assets/images/westerdressimages-1-225x225.webp" alt="" />
                <div className="card-box">
                    <h5 className="card-date align-left mbr-semibold mbr-white mbr-fonts-style display-4">
                        <strong>2025</strong>
                    </h5>
                    <h4 className="card-title align-left mbr-semibold mbr-white mbr-fonts-style display-5">
                        <strong>Dresses</strong>
                    </h4>
                </div>
            </div>
            
        </div> */}

        {/* <div className="card p-3 col-12 col-md-6 col-lg-4">
            <div className="card-wrapper card2">
                <img src="assets/images/skirtsimages-194x259.webp" alt="" />
                <div className="card-box">
                    <h5 className="card-date align-left mbr-semibold mbr-white mbr-fonts-style display-4">
                        <strong>2026</strong>
                    </h5>
                    <h4 className="card-title align-left mbr-semibold mbr-white mbr-fonts-style display-5">
                        <strong>Skirts</strong>
                    </h4>
                </div>
            </div>
            
        </div> */}
        {!productreload && productByName.map((product,key)=>(
            <div className="card p-3 col-12 col-md-6 col-lg-4">        
            <div className="card-wrapper card3">
                <img src={product.productImage} alt={product.name} />                
                <div className="card-box">                    
                    <h4 className="card-title align-left mbr-semibold mbr-white mbr-fonts-style display-6" style={{textAlign:'left'}}>
                        <strong>{product.name}</strong>
                    </h4>
                    <div className="card-button">
                                                    <p style={{width:'auto'}} className="mbr-button mbr-fonts-style display-4 align-left" onClick={() => navigate(`product/${product.id}`)}>
                                                        <strong style={{backgroundColor:'#ff4712',color:'#f0f0f5'}}>Quick View</strong>
                                                    </p>
                                                </div>                    
                </div>
            </div>
            
        </div>
        ))}
    </div>
</div>
</section>
        {/* <section data-bs-version="5.1" className="slider6 mbr-embla cid-u7GmJjAsBK" id="slider6-1t">  
    <div className="position-relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-4">
                    <h3 className="mbr-section-subtitle mbr-fonts-style display-4"><strong>Elegant Ethnic</strong></h3>
                    <h2 className="mbr-section-title mbr-fonts-style display-2">
                        <strong>Collections</strong></h2>
                </div>
                <div className="col-12 col-lg-6">
                    <p className="mbr-text mbr-fonts-style display-7"><strong>Gown 
</strong><br/><strong>Kurta Set
</strong><br/><strong>Salwar Kameez</strong></p>
                </div>                
            </div>
        </div>
    </div>
</section>
<section data-bs-version="5.1" className="features1 cid-t3PcnWcHIN" id="features1-2">
        <div className="container">            
            <div className="row">
            {!categoryreload && categoryByName.map((cat,key)=>(
              <div style={{cursor:'pointer'}} className="col-12 col-lg-4 col-md-12 features-card" key={key}>
              <img src={cat.categoryImage} alt={cat.name} />              
          </div>
            )
            )
            } <div className="col-12 col-lg-12"></div>            
            </div>
        </div>
    </section>
        <section data-bs-version="5.1" className="image1 cid-u7DwUe6bE1 mbr-fullscreen cid-u7GmJjAsBK" id="image1-1r">
    <div className="container">       
        <div className="row">
            <div className="col-12 col-lg-12 image-wrap-2">
                <div className="row">
                {!productreload && productByName.map((product,key)=>(
                    <div className="col-md-6 image-wrap" key={key}>
                    <div className="image-wrapper">
                        <img src={product.productImage} alt={product.name} />
                        <a href="#" className="card-link">
                            <div className="card-wrapper">
                                <h4 className="mbr-title mbr-fonts-style display-5">
                                    <strong>{product.name}</strong>
                                </h4>
                                <p className="mbr-text mbr-fonts-style display-7">
                                    {product.productPrice}
                                </p>
                                <div className="card-wrapper">
                                <div className="card-button">
                                                    <p className="mbr-button mbr-fonts-style display-4" style={{width:'auto'}} onClick={() => navigate(`product/${product.id}`)}>
                                                        <strong>Quick View</strong>
                                                    </p>
                                                </div>
                                </div>                                
                            </div>
                        </a>
                    </div>
                </div>
                ))}
                </div>
            </div>
        </div>
    </div>
</section> */}
        </>
    )
})