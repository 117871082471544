import { observer } from 'mobx-react-lite';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment
  } from "semantic-ui-react";
  import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
export default observer(function TermsConditionsPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min:number, max:number) => { 
        return Math.floor(Math.random()  
                * (max - min + 1)) + min; 
    };
    return (
      <section data-bs-version="5.1" className="pricing3 cid-u7fq8ip3pz" id="pricing03-3">
      <div className="container">
          <div className="mbr-section-head mb-5">
              <h3 className="mbr-section-title mbr-fonts-style mb-0 display-2"><strong>Terms and Conditions</strong></h3>
          </div>
          <div className="row">
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Acceptance of Terms</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">By accessing or using our website, you agree to be bound by these Terms and Conditions, as well as our Privacy Policy. If you do not agree with any part of these terms, you may not access our website or use any of our services.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Use of Website</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">You may use our website for lawful purposes only and in accordance with these Terms and Conditions. You agree not to engage in any activity that could damage, disable, overburden, or impair our website or interfere with any other party's use of our website.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Use of Website</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">You may use our website for lawful purposes only and in accordance with these Terms and Conditions. You agree not to engage in any activity that could damage, disable, overburden, or impair our website or interfere with any other party's use of our website.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Product Information</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">We make every effort to ensure that the information provided on our website, including product descriptions, prices, and availability, is accurate and up-to-date. However, we cannot guarantee that all information is error-free, and we reserve the right to correct any errors or inaccuracies without notice.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Ordering and Payment</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">By placing an order through our website, you warrant that you are legally capable of entering into binding contracts and that all information you provide is accurate and complete. You agree to pay all charges incurred by you or any users of your account, including applicable taxes and shipping fees.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Shipping and Delivery</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">We aim to process and ship orders in a timely manner, but delivery times may vary depending on your location and shipping method. We are not responsible for any delays or issues caused by third-party shipping carriers.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Returns and Refunds</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">Please refer to our Refund Policy for information about returns, refunds, and cancellations.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Limitation of Liability</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">In no event shall <strong>SS Enterprises</strong>, its directors, officers, employees, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our website or the purchase of products from us.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Governing Law</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">These Terms and Conditions shall be governed by and construed in accordance with the laws of Gurugram, without regard to its conflict of law provisions.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Changes to Terms</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">We reserve the right to modify or update these Terms and Conditions at any time without prior notice. It is your responsibility to review these terms periodically for changes. Your continued use of our website after any modifications indicates your acceptance of the updated terms.<br/></p>
                      </div>
                  </div>
              </div>
              <div className="card col-12">
                  <div className="card-wrapper">
                      <div className="top-line row">
                          <div className="col">
                              <h4 className="card-title mbr-fonts-style display-5"><strong>Contact Information</strong></h4>
                          </div>
                          <div className="col-auto">                            
                          </div>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">If you have any questions or concerns about these Terms and Conditions, please contact us at <strong><em>+918791602189/ssenterprises28101992@gmail.com</em></strong>.<br/></p>
                      </div>
                  </div>
              </div>
  
          </div>
      </div>
  </section>
    )
})