import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyD37vuFBqZM0558K33s5IJqa8Y7QM2V27A",
  authDomain: "enterprisesss-e12d7.firebaseapp.com",
  projectId: "enterprisesss-e12d7",
  storageBucket: "enterprisesss-e12d7.appspot.com",
  messagingSenderId: "327362404576",
  appId: "1:327362404576:web:655eedffce30b9a6783085"
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);