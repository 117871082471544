import { observer } from "mobx-react-lite";
import { useState,useEffect } from "react";
import { useStore } from "../../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup,signInWithEmailAndPassword } from "firebase/auth";
import { auth, db} from "../../../firestore-config";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import {quantityvalue} from "../../../app/common/options/categoryOptions";

export default observer(function ResponsiveProductPage() {
    const [toggleBtn, setToggleBtn] = useState(true);    
  const toggle = () => setToggleBtn(val => !val);
  const { commonStore,modalStore,productStore,
    userStore:{user,setUser}} = useStore();
  const navigate = useNavigate();
  const {loadProduct,product,setProduct,setProductReload} = productStore;
  const { id } = useParams();
  const [message, setMessage] = useState("Please go next to login in with your google account to proceed next steps");
  const [quantity, setQuantity] = useState(1);
  const [totalamount, setTotalAmount] = useState(0);
  const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;      
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      const newuser = 
        {
            uid: user.uid,
          name: user.displayName,
          token: user.refreshToken,
          email:user.email,
          authProvider: "google",
          email: user.email,
          active: false,
          photoUrl:user.photoURL
        }
      if (docs.docs.length === 0) {            
        await addDoc(collection(db, "users"),newuser);            
      }
      setUser(newuser);
      setMessage(`Welcome, ${newuser.name}`)
      navigate('/thankyou');
    } catch (err) {
      console.error(err);      
    }
  };
  useEffect(() => {
    if (id)
        {
            loadProduct(id).then(product => {              
              setProduct(product);
              setTotalAmount(parseInt(quantity) * product.productPrice);
            })                        
        }        
        if(product === null || product === undefined || product.id === "")
                    navigate('/home');        
  },[])  
      const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min, max) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
  };
    return (
        <>
       <section data-bs-version="5.1" className="features6 cid-u7xjrtX9CL cid-u7uDrIVq3y" id="features06-1m">
    <div className="container">
        <div className="row">
            
        </div>
        <div className="row">
            <div className="col-lg-6">
                <div className="row row-h">
                    <div className="card col-12 col-md-12 col-lg-12">
                        <div className="card-wrapper">
                            <div className="card-box align-center">                                
                            
          <div className="item features-image col-12 col-md-6 col-lg-12">
                <div className="item-wrapper">
                    <div className="item-img">
                        <img src={`/${product.productImage}`} alt={product.name} />
                    </div>
                    <div className="item-content">
                        <p className="mbr-text mbr-fonts-style mt-3 mb-0 display-4"><strong>{product.name}</strong></p>
                        <p className="price mbr-fonts-style mt-2 mb-0 display-7">INR {product.productPrice}</p>                        
                        <div className="mbr-text mt-4 mb-5">{product.title}
                        </div>
                    </div>
                </div>
            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="row">                   
                    <div className="card col-12 col-md-12 col-lg-12">
                        <div className="card-wrapper">                                                 
                            <div className="card-box align-left">
                                <h5 className="card-title mbr-fonts-style pb-3 display-5"><strong>Order Details</strong></h5>
                                <p className="card-text mbr-fonts-style display-4">
                                <select className="card-text mbr-fonts-style display-4" onChange={(e)=>{
                                setQuantity(parseInt(e.target.value));                                
                                setTotalAmount(parseInt(e.target.value) * product.productPrice);
                            }}>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select>
                                </p>
                                <p className="card-text mbr-fonts-style display-4">{quantity} {product.name} = <strong> {totalamount} INR </strong>
                                </p>                               
                                <div className="iconfont-wrapper align-right" onClick={signInWithGoogle}
              style={{cursor:'pointer'}}>
                                    <span className="socicon-google socicon mbr-iconfont mbr-iconfont-social"></span>
                                </div>

                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
})